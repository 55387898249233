import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const card = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#ebebeb",
  textAlign:"center",
  flexDirection:"column"
};

const img_style={
  width:"250px",
}


const CanJoin = ({event}) => {
  return (
    <div style={card}>
      <Card.Link href="https://iasscore.in/">
        <Card.Img variant="top" src="https://iasscore.in/assets/img/iasscore-logo.png" style={img_style} />
      </Card.Link>
        <Card >        
          <Card.Body>
            <Card.Title>You cannot join the session yet.</Card.Title>
            <Card.Text>
            You can join the session on {event.start_date_time}.
            </Card.Text>
            <a className='btn btn-danger' href='https://iasscore.in/my-account'>Go to My Account</a>
          </Card.Body>
      </Card> 
    </div>
  )
}

export default CanJoin