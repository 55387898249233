import React from 'react'

export const Invalid = () => {
  return (
    <div>
        <h1 className='text-center text-danger pt-5'>
            Kindly Check Your Url or Meeting has been canceled...
        </h1>
    </div>
  )
}
