import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import loading from '../img/loading.json'

import "./join.css"

const Dashboard = () => {
  const [cookies, setCookie] = useCookies(['user']);
  const params=useParams();
  const navigate=useNavigate();

  useEffect(() => {  
    // setCookie('userId', '896702', { path: '/' });
    if(cookies.userId==undefined ||cookies.userId=='' ){
      navigate('/unauthorised');
    }     
  }, [cookies])
  
  // let meeting_number=params.id;

  // meeting_number=100;
  // if(meeting_number){
  //   setCookie('meeting_number', meeting_number, { path: '/' });
  //   console.warn('cookie has been set');
  // }

  return (
    <>
        {/* <button>
            <Link to='join/93323368329'>Join</Link>
        </button>
        <br/>
        <button>
            <Link to='start/93323368329'>Start</Link>
        </button> */}
        <div className='loading'>
          <Lottie animationData={loading} loop={true}  style={{width:"30%",margin:"auto"}}/>
        </div>
    </>
  )
}

export default Dashboard