import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const card = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#ebebeb",
  textAlign:"center",
  flexDirection:"column"
};

const img_style={
  width:"250px",
}


const ThankU = () => {
  return (
    <div style={card}>
      <Card.Img variant="top" src="https://iasscore.in/assets/img/iasscore-logo.png" style={img_style} />
        <Card >        
          <Card.Body>
            <Card.Title>Thanks for joining this session</Card.Title>
            <Card.Text>
              This session video will be available within 3 hours.
            </Card.Text>
            <a className='btn btn-danger' href='https://iasscore.in/my-account'>Go to My Account</a>
          </Card.Body>
      </Card> 
    </div>
  )
}

export default ThankU