import React, { useState,useEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./join.css"
import Lottie from "lottie-react";
import loading from '../img/loading.json'
import { Invalid } from './Invalid';
import Unauthorised from './Unauthorised';
import { AdminState } from "../Context/Context";
import { GET_SESSION_DETAILS } from '../API';
import CanJoin from './CanJoin';


ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');
function Mentorship_join_meeting() {
    const [cookies, setCookie] = useCookies();
    const [event,setEvent]=useState([]);
    const [invalid,setInvalid]=useState(0);
    const [isAuthenticate,setIsauthenticate]=useState(false);
    const [canJoin, setCanJoin] = useState(false);
    const navigate=useNavigate();
    const params=useParams();
    var schedule_id=params.id;
    const {is_loggedIn,setIs_loggedIn}=AdminState();
    
    const get_event_details=async()=>{
      const form_data = { 
        user_id:cookies.userId,
        schedule_id:schedule_id,    
      };

      const live_url='https://iasscore.in/api/mentorbox/mentorship_session_details';
      axios.post(live_url, form_data,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        const {data}=response;
        if(data.details){
          setEvent(data.details)  
          console.log(data.details);
          setIsauthenticate(data.details.access) 
          if(!data.details.access){
            navigate('/access-denied');
          }
        }else{
          setInvalid(1);
        }    
      }, (error) => {
        console.log(error);        
      }); 
    }
    useEffect(() => {
      if(is_loggedIn){
        get_event_details();
      }
    }, [is_loggedIn])

    useEffect(() => {
      if(is_loggedIn){
        start();
        document.title=event.title;
      }
    },[isAuthenticate])    
    const start=()=>{
      if(is_loggedIn && (isAuthenticate || cookies.userType=='0' || cookies.userType>=4)){
        
        if(event.meeting_id && event.can_join){
          setCanJoin(true);
          getSignature();
        }else{
          setCanJoin(false);
          console.log('You can not join the meeting');
        }
      }else{
        console.log('not authenticated');
        navigate('/access-denied');
      }    
    }
    
    var signatureEndpoint = 'https://conapi.iasscore.in'
    var sdkKey = '88Itp1a0NGJ7VTaVW3ZYpX6VLbCwhAdyD9Di';
    var meetingNumber = event.meeting_id
    var role = 0
    var leaveUrl = 'https://connect.iasscore.in/thank-you'
    var userName = cookies.userName ? cookies.userName.replaceAll("+", " "): ''
    var userEmail = cookies.userEmail
    var passWord = event.created_by
    var registrantToken = ''
  function getSignature() {    
    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }
  const schedule_joined_date_time_insert=()=>{
    const live_url="https://iasscore.in/api/mentorbox/mentorship_session_joined_data_insert";
    const api_params={
      "schedule_id": schedule_id,
      "user_id": cookies.userId,
    };
    axios.post(live_url, api_params,{
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
      }
    })
    .then((response) => {  
      console.log(response);    
    }, (error) => {
      console.log(error);        
    });
  }
  
  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'
    // console.warn('signature',signature);
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            // console.log(success)
            schedule_joined_date_time_insert();
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      {!is_loggedIn ? (
        <Unauthorised />
      ) : invalid ? (
        <Invalid />
      ) : !canJoin ? (
        <CanJoin  event={event}/>
      ) : (
        <div className='loading'>
          <Lottie animationData={loading} loop={true} style={{ width: "30%", margin: "auto" }} />
        </div>
      )}
    </div>
        
  );
}

export default Mentorship_join_meeting;
