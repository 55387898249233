import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import circle_loader from '../img/circle_loader.json'
import Lottie from "lottie-react";
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Unauthorised.css'
const Unauthorised = () => {
  const navigate = useNavigate();
  const [fields,setFields]=useState({});
  const [isLoading,setIsLoading]=useState(false);
  const [is_otp_sent,setIs_otp_sent]=useState(false);
  const Img_style={
    textAlign:"center",
    margin:"auto",
    width:"250px",
  };
  const [cookies, setCookie] = useCookies();
  const [key, setKey] = useState('home');
  const [isValid, setIsValid] = useState({'status':true,'error':''});

  const handleData = (e) => {
    const name=e.target.name;
    const value=e.target.value;
    setFields({...fields,[name]:value})
  };
  const login=(e)=>{
    e.preventDefault();
    if(!fields.email || !fields.password){
      setIsValid({'status':false,'error':'email and password are required'});
      return false;
    }else{
      setIsValid({'status':true,'error':''});
    }
    const url='https://iasscore.in/api/auth/site_login';
    setIsLoading(true);
    axios.post(url,fields,{
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
      }
    })
    .then((response)=>{
      response=response.data 
      if(response.status){
        setIsLoading(false);
        setCookie('userId', response.user_id, { path: '/' });
        setCookie('userEmail', response.email, { path: '/' });
        setCookie('userName', response.username, { path: '/' });
        setCookie('userImage', response.userImage, { path: '/' });
        setCookie('userCon', response.contact, { path: '/' });
        setCookie('rollNumber', response.rollNumber, { path: '/' });
        setCookie('mobileVerified', response.mobile_verified, { path: '/' });
        setCookie('emailVerified', response.email_verified, { path: '/' });
        setCookie('userType', response.user_type, { path: '/' });
        setCookie('salt', response.salt, { path: '/' });
        setCookie('logged_in', true, { path: '/' });
        window.location.reload();
      }else{
        setIsLoading(false);
        setIsValid({'status':false,'error':'email or password is incorrect'});
        // alert('email or password is incorrect')
      }
    },(error)=>{
      setIsLoading(false);
      console.log(error);
    });
  }

  const reset_state=(e) =>{
    console.log('clicked');
    setIsValid({'status': true,'error':''});
  }

  const send_otp=(e)=>{
    e.preventDefault();
    console.log(fields);
    if (validateMobileNumber()) {
      setIsValid({'status': true,'error':''});
      if(is_otp_sent){
        //verify otp
        if(!fields.otp){
          setIsValid({'status': false,'error':'Enter OTP'});
          return false;
        }
        setIsLoading(true);
        const url='https://iasscore.in/api/auth/verifyOtp';
        axios.post(url,{'mobile_number':fields.mobile,'otpIdentifier':'site_login','otpNumber':fields.otp},{
          headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
          }
        })
        .then((response)=>{
          setIsLoading(false);
          response=response.data
          // console.log(response);
          if(response.status){
            setIsValid({'status':true,'error':''})
            setCookie('userId', response.user_id, { path: '/' });
            setCookie('userEmail', response.email, { path: '/' });
            setCookie('userName', response.username, { path: '/' });
            setCookie('userImage', response.userImage, { path: '/' });
            setCookie('userCon', response.contact, { path: '/' });
            setCookie('rollNumber', response.rollNumber, { path: '/' });
            setCookie('mobileVerified', response.mobile_verified, { path: '/' });
            setCookie('emailVerified', response.email_verified, { path: '/' });
            setCookie('userType', response.user_type, { path: '/' });
            setCookie('salt', response.salt, { path: '/' });
            setCookie('logged_in', true, { path: '/' });
            window.location.reload();
          }else{
            setIsLoading(false);
            setIsValid({'status': false,'error':response.message});
          }
        }); 
      }else{
        //sent otp 
        setIsLoading(true);
        const url='https://iasscore.in/api/auth/sendOtp';
        axios.post(url,{'mobile_number':fields.mobile,'otpIdentifier':'site_login'},{
          headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
          }
        })
        .then((response)=>{
          setIsLoading(false);
          response=response.data 
          setIs_otp_sent(true);
          setIsValid({'status': true,'error':response.message});
        });        
      }
    } else {
      setIsLoading(false);
      setIsValid({'status': false,'error':'Pls enter valid mobile number.'});
    }
  }

  const validateMobileNumber = () => {
    // Define the desired pattern for a valid mobile number
    const mobileNumberPattern = /^[9876]\d{9}$/;
    return mobileNumberPattern.test(fields.mobile);
  };

  const resent_otp=()=> {
    setIsLoading(true);
    const url='https://iasscore.in/api/auth/sendOtp';
    axios.post(url,{'mobile_number':fields.mobile,'otpIdentifier':'site_login'},{
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
      }
    })
    .then((response)=>{
      setIsLoading(false);
      response=response.data 
      setIs_otp_sent(true);
      setIsValid({'status': true,'error':response.message});
    }); 
  }

  return (
    <>    
    <div className='row' style={{
      backgroundColor: "#d9d9d9",
      height: "100%",
      minHeight: "100vh",
    }}>
      <Col md={4}>
      </Col>
      <Col md={4}>
        <div className='card p-4 mt-3'>
          <img src="https://iasscore.in/assets/img/iasscore-logo.png" alt="" className='img-fluid' style={Img_style}  />
          <h3 className='text-center text-danger py-3'>User Login :</h3>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
              onClick={reset_state}
            >
              <Tab eventKey="home" title="Using Password" className={key === 'home' ? 'fade-in' : 'fade'} >
                <Form onSubmit={login}> 
                  <h6 className='text-danger'>{isValid.status ? '':isValid.error}</h6>                 
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    
                    <Form.Label>Email or Phone</Form.Label>
                    <Form.Control type="text" placeholder="Enter email or phone" name="email" value={fields.email} onChange={handleData} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name="password" value={fields.password} onChange={handleData} />
                  </Form.Group>
                  <div className='text-center'>                  
                    {isLoading ? (
                      <div className='loading'>
                        <Lottie animationData={circle_loader} loop={true}  style={{width:"50%",margin:"auto"}}/>
                      </div>
                    ) :(
                    <Button variant="primary" type="submit">
                      Login
                    </Button>
                    )}
                  </div>
                </Form>
              </Tab>
              <Tab eventKey="profile" title="Using OTP" className={key === 'profile' ? 'fade-in' : 'fade'} > 
                <Form onSubmit={send_otp}> 
                  {!is_otp_sent ? (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Enter Mobile No.</Form.Label>
                    <Form.Control type="number" placeholder="Enter Mobile No." name="mobile" value={fields.mobile} onChange={handleData} />
                  </Form.Group>
                  ):(
                    <>
                      <h6 className='text-danger'>{isValid.status ? isValid.error:''}</h6>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{display:'flex',justifyContent:'space-between'}}> <span>Enter OTP.</span> <button type="button" className='btn btn-sm btn-danger' onClick={resent_otp}>RESEND OTP</button></Form.Label>
                        
                        <Form.Control type="number" placeholder="Enter OTP." name="otp" value={fields.otp} onChange={handleData} />
                      </Form.Group>
                    </>
                  )}                
                  <div className='text-center'>
                   
                    {!isValid.status && (
                      <p className="text-danger">{isValid.error}</p>
                    )}
                    {isLoading ? (
                      <div className='loading'>
                        <Lottie animationData={circle_loader} loop={true}  style={{width:"50%",margin:"auto"}}/>
                      </div>
                    ) :(
                    <Button variant="primary" type="submit">
                      {is_otp_sent ? 'VERIFY OTP':'GET OTP'}
                    </Button>
                    )}
                  </div>
                </Form>
              </Tab>
            </Tabs>          
        </div>        
      </Col>
    </div>
      
    </>
  )
}

export default Unauthorised